import React from "react";
import ModelId from "../../constants/ModelId";
import ObjectDetector from "../../components/ObjectDetector";
import Strings from "../../constants/Strings";
import FormName from "../../constants/FormName";

const IMAGES: NodeRequire[] = [
  require("../../images/models/lake_aerial_1.jpg"),
  require("../../images/models/lake_aerial_2.jpg"),
  require("../../images/models/lake_aerial_3.jpg"),
  require("../../images/models/lake_aerial_4.jpg"),
  require("../../images/models/lake_aerial_5.jpg"),
  require("../../images/models/lake_aerial_6.jpg"),
  require("../../images/models/lake_aerial_7.jpg"),
  require("../../images/models/lake_aerial_8.jpg"),
];

const ImageObjectDetectionAerial = () => {
  return (
    <ObjectDetector
      modelId={ModelId.imageObjectDetectionAerial}
      title={Strings.Models.ImageObjectDetectionAerial.title}
      description={Strings.Models.ImageObjectDetectionAerial.description}
      images={IMAGES}
      callToActionTitle={Strings.Models.ImageObjectDetectionAerial.Form.title}
      callToActionFormName={FormName.imageObjectDetectorAerial}
    />
  );
};

export default ImageObjectDetectionAerial;
